import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts"
import Seo from "../components/seo"
import Header from "../components/header"
import PageTitle from "../components/pageTitle"
import PageIntro from "../components/pageIntro"
import Features from "../components/features"
import imgGallery from "../utils/imgGallery"

class PageTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      blockHeight: 375,
    }
  }

  componentDidMount() {
    if (document.querySelector(".block--contact")) {
      const block = document.querySelector(".block--contact")
      this.setState({ blockHeight: block.clientHeight })
      window.addEventListener("resize", () => {
        this.setState({ blockHeight: block.clientHeight })
      })
    }
  }

  pageContent(pageId, currentPage) {
    if (pageId === 40) {
      return (
        <div className="row">
          <div className="col-bp1-12 col-bp5-7 col-bp6-6">
            <div className="block block--contact">
              <div
                dangerouslySetInnerHTML={{ __html: currentPage.content }}
              ></div>
            </div>
          </div>
          <div className="col-bp1-12 col-bp5-5 col-bp6-6">
            <div className="map">
              <iframe
                style={{ height: this.state.blockHeight }}
                title="contact-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10087.85657792264!2d-1.064209994520288!3d50.79477306740657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6b9d03b852237159!2sPortsmouth+Rotary+Housing+Association!5e0!3m2!1sen!2suk!4v1560351776977!5m2!1sen!2suk"
                width="600"
                height="450"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row center-bp1">
          <div className="col-bp1-12 col-bp4-8 col-bp6-6">
            <div className="entry">
              <div
                dangerouslySetInnerHTML={{ __html: currentPage.content }}
              ></div>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    const currentPage = this.props.data.allWpPage.edges[0].node,
      pageId =
        currentPage.parentId === 0 ? currentPage.id : currentPage.parentId,
      pageTitle = currentPage.parentId === 0 ? currentPage.title : false,
      introTitle =
        currentPage.introduction !== null
          ? currentPage.introduction.introTitle
          : "",
      introDesc =
        currentPage.introduction !== null ? currentPage.introduction.intro : "",
      seoTitle = currentPage.title,
      seoDesc = "",
      gallery =
        currentPage.imageGalleryContent !== null
          ? currentPage.imageGalleryContent
          : null

    return (
      <Layout>
        <div className="page">
          <Seo seoTitle={seoTitle} seoDesc={seoDesc} />
          <Header />
          <PageTitle pageId={pageId} pageTitle={pageTitle} />
          <PageIntro introTitle={introTitle} introDesc={introDesc} />

          <section className="section sectionWhite section--close-btm">
            <div className="containerFluid">
              {this.pageContent(currentPage.databaseId, currentPage)}
              {imgGallery(gallery, true)}
            </div>
          </section>

          <section className="section sectionWhite section--close-btm">
            <div className="features">
              <div className="containerFluid">
                <div className="row">
                  <div className="col-bp1-12">
                    <Features />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          databaseId
          title
          content
          parentId
          slug
          introduction {
            introTitle
            intro
          }
          forms {
            forms
          }
          imageGalleryContent {
            imageGallery {
              localFile {
                name
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
